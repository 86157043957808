@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.bg-glow {
    height: calc(100% - 50px);
    background-repeat: no-repeat;
    background-position: center top;
}

.link-menu {
    @apply text-white flex-shrink-0 inline-flex items-center justify-center h-10 w-10 rounded-lg
}
